export const index_name = process.env.GATSBY_ALGOLIA_INDEX_NAME;

export const currency = process.env.GATSBY_CURRENCY;
export const country = "Scotland";
export const defaultArea = process.env.GATSBY_DEFAULT_AREA ? process.env.GATSBY_DEFAULT_AREA : "London";
export const defaultArea_Slug = defaultArea.toLowerCase().replace(/ /g,'-');

export const defaultArea_Newhomes = process.env.GATSBY_DEFAULT_AREA_NEWHOMES ? process.env.GATSBY_DEFAULT_AREA_NEWHOMES : "London";
export const defaultArea_Newhomes_Slug = defaultArea_Newhomes.toLowerCase().replace(/ /g,'-');

export const site_name = process.env.GATSBY_SITE_NAME;

export const site_bviewing_agent_id = "";

export const IsAllAgentReview = true;
export const hasMyaccount = (process.env.GATSBY_MYACCOUNT || null) === '1' ? true : true;

// export const PageLinks = {
//     about: "about-dj-alexander",
//     general_enquiry: "general-enquiry",
//     news_insight: "about-dj-alexander/insights",
//     branch_finder: "about-dj-alexander/branch-finder",
//     area_cover: "about-dj-alexander/areas-we-cover",
//     branch_contact: "contact-us",
//     team_contact: "contact-us",
//     teams: "about-dj-alexander/meet-the-team",
//     request_valuation: "property-valuation",
//     privacy_policy: "privacy-policy",
//     book_a_viewing: "book-a-viewing",
//     book_a_valuation: "property-valuation/home-visit-valuation",
//     send_to_friend: "send-to-friend",
//     property_to_rent: "property/to-rent",
//     property_for_sale: "property/for-sale",
//     reviews: "about-dj-alexander/customer-reviews",
//     property_management: "landlords/property-management",
//     financial_services: "financial-services"
// }

export const PropertySearchURL = {
    residential: {
        sales:"/property/for-sale/",
        lettings:"/property/to-rent/",
        newhomes: "/new-homes/for-sale/"
    },
    commercial: {
        sales:"/commercial/for-sale/",
        lettings:"/commercial/to-rent/",
    },
    auctions: {
        sales:"/auctions/for-sale/"
    }
}

export const PropertyDetailsURL = {
    residential: {
        sales:"/property-for-sale/",
        lettings:"/property-to-rent/",
        newhomes:"/new-homes-for-sale/"
    },
    commercial: {
        sales:"/commercial-for-sale/",
        lettings:"/commercial-to-rent/",
    },
    auctions: {
        sales:"/auctions-for-sale/"
    }
}

export const SearchTitle = {
    residential: {
        sales:"Properties for sale",
        lettings:"Properties to rent",
        newhomes:"New developments"
    },
    commercial: {
        sales:"Commercial properties for sale",
        lettings:"Commercial properties to rent",
    },
    auctions: {
        sales:"Auction properties for sale"
    }
}

export const PopularSearch_Areas = [ 
    { value: "edinburgh", label: "Edinburgh" },
    { value: "glasgow", label: "Glasgow" },
    { value: "st-andrews", label: "St. Andrews" },
    { value: "fife", label: "Fife" }   
]

export const PopularSearch_Types = [ 
    { value: "house", label: "Houses" },
    { value: "apartment", label: "Apartments" },
    { value: "bungalow", label: "Bungalows" },
    { value: "maisonette", label: "Maisonettes" }   
]

export const sortByList = [ 
    { value: "HighestPrice", label: "Highest Price" },
    { value: "LowestPrice", label: "Lowest Price" }, 
    { value: "Newest", label: "Newest" },
    { value: "Oldest", label: "Oldest" }  
]

export const SearchType = {
    sales:"Buy",
    lettings:"Rent",
    newhomes:"New homes"
}

export const FurnishedType = [    
    { value: 'furnished', label: 'Furnished' },
    { value: 'unfurnished', label: 'Unfurnished' },
    { value: 'part-furnished', label: 'Part furnished' },
]

export const leadooPageSlot = {
    sell_property:"Sellers",
    landlords:"Landlords",
    sales:"Sale listing",
    lettings:"Rental listing",
    branch_finder:"Branch finder",
    general_enquiry:"General enquiry",
    property_valuation:"Valuation",
    home:"Valuation"
}
